import axios from 'axios';
import { useRouter } from 'vue-router';
import { decodeJwt } from 'jose';
import { useToast } from 'primevue/usetoast'
import { reactive, toRefs } from 'vue';

export const useApi = () => {
	const state = reactive({
		api_error: '',
		api_call_pending: false,
		api_response_data: null
	});
	const router = useRouter();
	const toast = useToast();

	const isRequestTokenValid = (request) => {
		let isValid = false;

		try
		{
			if (request.headers && request.headers['Authorization'])
			{
				const jwt = request.headers['Authorization'].replace('Bearer ', '');
				const { exp } = decodeJwt(jwt);
				if (Math.floor(Date.now() / 1000) < exp)
				{
					isValid = true;
				}
			}
			else
			{
				isValid = true;
			}
		}
		catch (error)
		{
			console.log('EXCEPTION VALIDATING JWT');
			isValid = false;
		}

		return isValid;
	}

	const callApi = async (requestOptions, callback) => {
		if (isRequestTokenValid(requestOptions)) {
			state.api_call_pending = true;
			state.api_error = null;
			state.api_response_data = null;

			try {
				const response = await axios(requestOptions);

				if (response.status === requestOptions.successStatusCode) {
					state.api_response_data = response.data;
					state.api_call_pending = false;
					state.api_error = null;

					if(callback) {
						callback(response.data);
					}
				} else {
					throw Error('Error performing API request');
				}
			} catch (e) {
				state.api_call_pending = false;
				if (e.response && e.response.data && e.response.data.errors) {
					state.api_error = e.response.data.errors;
				} else {
					state.api_error = e.message || 'Unexpected Error!';
				}
			}
		} else {
			router.push({ name: 'login' })
				.then(() => {
					toast.add({
						severity: 'error',
						summary: 'API Error',
						detail: 'User not logged in.',
						life: 3000
					});
				});
		}
	}

	return {...toRefs(state), callApi};
}