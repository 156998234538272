const API_ROOT = process.env.VUE_APP_API_ROOT;

export const userLogin = ({ username, password }) => {
	return {
		method: 'POST',
		url: `${API_ROOT}/login`,
		data: {
			username,
			password
		},
		successStatusCode: 200
	};
}

export const sitesList = (jwt) => {
	return {
		method: 'GET',
		url: `${API_ROOT}/sites`,
		headers: {
			'Authorization': `Bearer ${jwt}`
		},
		successStatusCode: 200
	}
}

export const reservationCreate = (reservation, jwt) => {
	return {
		method: 'POST',
		url: `${API_ROOT}/reservations/`,
		headers: {
			'Authorization': `Bearer ${jwt}`
		},
		data: reservation,
		successStatusCode: 201
	}
}

export const reservationDelete = (reservation_id, jwt) => {
	return {
		method: 'DELETE',
		url: `${API_ROOT}/reservations/${reservation_id}`,
		headers: {
			'Authorization': `Bearer ${jwt}`
		},
		successStatusCode: 204
	}
}

export const reservationsList = (jwt) => {
	return {
		method: 'GET',
		url: `${API_ROOT}/reservations`,
		headers: {
			'Authorization': `Bearer ${jwt}`
		},
		successStatusCode: 200
	}
}

export const reservationsListBySite = (siteId, jwt) => {
	return {
		method: 'GET',
		url: `${API_ROOT}/sites/${siteId}/reservations`,
		headers: {
			'Authorization': `Bearer ${jwt}`
		},
		successStatusCode: 200
	}
}